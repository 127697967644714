export const LOGIN_USERS = 'LOGIN_USERS';
export const SET_NET_STATUS = 'SET_NET_STATUS';

export const loginUsers = ( users ) => ({
    type: LOGIN_USERS ,
    payload: users
  });

  export const loadLoggedUser = ( users ) => {
    
      return {
      type: LOGIN_USERS ,
      payload: users
    }
  };

  export const setNetworkStatus = ( status ) => ({
    type: SET_NET_STATUS ,
    payload: status
  });