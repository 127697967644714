export const LOAD_WORKERS = 'LOAD_WORKERS';
export const SET_WORKERS = 'SET_WORKERS';
export const SET_CURRENT_WORKER = 'SET_CURRENT_WORKER';

export const loadWorkers = ( workers ) => ({
    type: LOAD_WORKERS,
    payload: workers
  });

export const setWorkers = ( workers ) => ({
    type: SET_WORKERS,
    payload: workers
  });


export const setCurrentWorker = ( id , act ) => {
 
let obj = {
id: id ,
act: act  
}

return (
  {
    type: SET_CURRENT_WORKER,
    payload: obj
  })
};


  