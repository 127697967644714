export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const SET_POINTS = 'SET_POINTS';

export const loadSettings = ( settings ) => ({
    type: LOAD_SETTINGS ,
    payload: settings
  });

export const setPoints = ( points ) => ({
    type: SET_POINTS ,
    payload: points
  });  