import React from 'react';
import ReactDOM from 'react-dom';

import { store } from "./store";
import { Provider } from "react-redux";

import './index.css';
import App from './components/App';
//import App from './testApp/App';
//import App from './testApp/AppTest1';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(
    <Provider store={store}>
    <App />
    </Provider>,
    document.getElementById("root")    
    );



serviceWorker.unregister();
