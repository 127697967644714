import React from 'react';
import "./AddNewConsumer.css";
import {urlAppFile} from"../global";

//czy menu ma być przesuwane myszką
//tymczasowo wyłączone ponieważ wymaga poprawy
const staticMenu = true;

let pos = {x: 0 , y: 0};  
let mMove = false;

const removeConsumer = (props) => {
 
    const handleMouseDown=(e)=>
    {
    if( !staticMenu) {
        let mainBar = document.getElementById('mainBar');
        mainBar.style.position = 'absolute';
        let xFar = e.pageX - mainBar.offsetLeft ;
        let yFar = e.pageY -mainBar.offsetTop ;
        pos = { 
            x: xFar,
            y: yFar
        }
        mMove = true;
    }
    }  

    const handleMouseUp=(e)=>
    {
        mMove = false;
    } 

    const handlerMouseMove=(ex)=>
    {
        if(mMove) {
            let mainBar = document.getElementById('mainBar');
            mainBar.style.left = `${ex.pageX-pos.x}px`; 
            mainBar.style.top = `${ex.pageY-pos.y}px`;
            }
    }
 
 window.addEventListener('mouseup' , ()=>{ 
 mMove = false;
 })  
 
 const handleYesButton=()=> {
    var objCon = { 
        Action: "removeConsumer" ,
        id: props.consumer.id
     };
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(data => {
            if(data.status === "success") {
                    //alert("usunięto");
                    props.refreshConsumers();
                    props.handleRemoveConsumer();
            }
            if(data.status === "error") {
                alert("Nie można usunąć klienta");
            }
        }
        )
 }

 let removeApp = (
    <div className='bgOpenMenu'>
        <div className="openMenu">
            <div className="mainRow">
                <div className="row-1">
                        <div id="mainBar" className="mainBarDelCon" onMouseMove={handlerMouseMove}>
                            <div id="topBar" className="topBar" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                                <p style={{textAlign: "left"}}>Usuwanie klienta</p>
                            </div>
                            <div className="centralBar">
                                <p>Czy na pewno chcesz usunąć klienta <b>{props.consumer.name} {props.consumer.surname}</b> z bazy danych?</p>

                                <div style={{clear:"both"}}></div>
                            </div>
                            <div className="bottomBar">
                                <div className="buttons_main">
                                <button type="button" className="btn btn-dark btn-sm button-make" onClick={handleYesButton}>Tak</button>                  
                                <button type="button" className="btn btn-dark btn-sm button-close" onClick={props.handleRemoveConsumer}>Nie</button>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
 );
    return ( 
        <>
       {removeApp}
        </>

     );
}
 
export default removeConsumer;