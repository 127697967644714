import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import VisitApp from "./VisitApp.js";

import { setVisits } from '../actions/visitsActions';
import { setFrozenApp } from '../actions/netStatusActions';
import { setNetStatus } from '../actions/netStatusActions';

import { webAddress , refreshDayCalendar , LIMIT_ERROR_AUTO_REFRESH } from '../global';
import { loadVisitsOfDayFromDB , getIdInterval , setIdInterval , FREE , appNetStatus}  from '../customfunc';

import './DayCalendarApp.css';

const DayCalendarApp = () => { 
const dispatch = useDispatch();
const currentDateTime = useSelector(store => store.currentDateTime );
//const user = useSelector(store => store.user );
const appSettings = useSelector(store => store.netStatus );

let styleDayBack = 'mainCalDiv';
let counterErrorAutoRefresh = 0;

const refreshVisits=()=>{
    const interval = setInterval(()=>{
          if(appNetStatus() === FREE) {
            //appNetStatus(BUSY);
            //dispatch( setFrozenApp ( true ) ) ;
            const prom = loadVisitsOfDayFromDB( currentDateTime );
            prom.then((resolve ) => {
                if(typeof resolve === 'object' && (!Array.isArray(resolve)) && resolve !== null  ) {
                    if(resolve.session && (resolve.session !== undefined) ) {
                        if(resolve.session.status) {
                        counterErrorAutoRefresh = 0;
                        clearInterval(getIdInterval());
                        dispatch( setNetStatus(true) );
                        //appNetStatus(FREE);
                        resolve.session.status === 'notLogged' && (document.location.href=webAddress);  
                        }  
                    }

                    if(resolve.error) {  
                        counterErrorAutoRefresh += 1;
                        if(counterErrorAutoRefresh >= LIMIT_ERROR_AUTO_REFRESH) {
                            dispatch( setNetStatus(false) );
                            }

                        //appNetStatus(FREE); 
                    }
                }
                if(Array.isArray(resolve)) {
                    if(appNetStatus() === FREE) { //Na wszelki wypadek gdyby wczytywanie odbyło się przed kliknięciem zmiany daty 
                        //i trwało dłużej niż ładowanie wyzyty automatycznie - zabezpieczenie dodatkowe aby dispatch nie zmienił stanu
                        dispatch( setVisits(resolve) ); //ustawienie wizyt
                        }
                    counterErrorAutoRefresh = 0;    
                    //else console.log('Odrzucono dispatch: ' , appNetStatus()) ;   
                    dispatch( setNetStatus(true) ); // ustawienie działającej sieci
                    dispatch( setFrozenApp(false) ); // odmrożenie applikacji
                    //appNetStatus(FREE);   //
                }
            })
            prom.catch((err) => {
                console.log("Mamy tu errora");
                console.log(err);

                //appNetStatus(FREE);
            })    
            }
            //else console.log('warunek odrzucony!!!');
            
      } , refreshDayCalendar );
      return interval;
  }

useEffect( ()=> {
    const interval1 = refreshVisits();
    setIdInterval(interval1);
    return () => clearInterval(getIdInterval());
    
} , [currentDateTime] );

if(currentDateTime.getDay() === 6) styleDayBack += ' saturdayCalendarStyle';
if(currentDateTime.getDay() === 0) styleDayBack +=' sundayCalendarStyle';

let stText = !appSettings.netStatus ? 'Brak połączenia!!!' : '';
return (        
    <>
    <div className='networkInfo' >{stText}</div>
    <div className={styleDayBack} >    
        <div style={{width: "90%" , margin: "0px auto"}}>
            <div style={{ marginTop: "10px" , width: "100%" }}>          
                <VisitApp />
            </div>
        </div>
    </div>
    </>   
    );
    
    }
 
export default DayCalendarApp;