import { combineReducers } from 'redux';
import { user } from './userReducers';
import { currentDateTime } from './dateReducers';
import { workers } from './workersReducers.js';
import { consumers } from './consumersReducers';
import { bVisibleMenu } from './bVisibleMenuReducers';
import { currentVisibleSite } from './currentVisibleSiteReducers';
import { kindCalendar } from './kindCalendarReducers';
import { visits } from './visitsReducers';
import { settings } from './settingsReducers';

//do testów
import { optionVisit } from './optionVisitReducers';
import { refresh } from './refreshReducers';
import { netStatus } from './netReducers';


export default combineReducers({
  settings ,
  user ,
  bVisibleMenu ,
  currentVisibleSite ,
  kindCalendar ,  
  currentDateTime ,
  workers ,
  consumers ,
  visits ,
  optionVisit , 
  refresh ,
  netStatus
});