//ustawienia aplikacji
//export const webAddress = 'http://192.168.0.102:3000';
export const webAddress = 'https://sm.cinnamon.wielun.pl/';

//ustawienie miejsca pliku app.php
export const urlAppFile = 'app.php';
//export const urlAppFile = "app.php";

//you need to change to 'cors' in production version!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const corsMode = 'cors';

//Ustawienia kalendarza dziennego - TODO powinny być wczytywane z bazy danych
export const startHour = 8; // początkowa godzina w kalendarzu
export const endHour = 20; // końcowa godzina w kalendarzu
export const heightRowCalendar = 55; //szerokość wiersza kalendarza
export const refreshDayCalendar = 10000; //altualizacja co 10s.

//strona startowa
export const defaultSite = 1;   //zakładka startowa 1: kalendarz, 2: Pracownicy ... itd
//obiekt używany do komunikacji pomiędzy kalendarzami. Miesięcznym, rocznym lub dziennym. 
export const defaultCalendar = {
  kind: 2 ,  //1: miesieczny, 2: dzienny, 4: roczny , 10 - nowy client(dzienny) , 18 edycja klienta(dzienny)
  data: null
   }   

//   
export const LIMIT_ERROR_AUTO_REFRESH = 3;   

//używane w Top.js w celu podziału diva na właściwe proporcje
export const MAX_WIDTH_MOBILE = 768;

export function setActiveOfWorker( id , active ) {
  return new Promise((resolve , reject) => {  
    var objCon = { 
      Action: "setActiveOfWorker" ,
      id ,
      active 
    };
  
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.text())
        .then(json => {
          resolve( json );
        }
        ) 
      })
}

export function setVisibleOfWorker( id , visible ) {
  return new Promise((resolve , reject) => {  
    var objCon = { 
      Action: "setVisibleOfWorker" ,
      id ,
      visible
    };
  
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.text())
        .then(json => {
          resolve( json );
        }
        ) 
      })
}

export function loadVisitOfConsumer( id ) {
  return new Promise((resolve , reject) => {  
    var objCon = { 
      Action: "loadVisitOfConsumer" ,
      id
    };
  
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          resolve( json );
        }
        )
        .catch( error => (reject(error))) 
      })
}