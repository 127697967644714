import { LOAD_WORKERS} from '../actions/workersActions';
import { SET_WORKERS} from '../actions/workersActions';
import { SET_CURRENT_WORKER } from '../actions/workersActions';

export const workers = (state = null, action) => { // (1)
    switch (action.type) { // (2)
      case LOAD_WORKERS:
        return action.payload;

      case SET_WORKERS:
        return action.payload;

      case SET_CURRENT_WORKER:
        {
        return state;  
        }

      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }