import { SET_OPTION_VISIT } from '../actions/optionVisitActions';

export const optionVisit = (state = null, action) => { // (1)
    switch (action.type) { // (2)
      case SET_OPTION_VISIT: {
       return action.payload; 
      }       

      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }