import React from 'react';
import { useDispatch , useSelector } from 'react-redux';

import { setDate } from '../actions/dateAction';
import { changeCalendar } from '../actions/kindCalendarActions';
import { setVisits } from '../actions/visitsActions';

import { loadVisitsOfDayFromDB } from '../customfunc';

import './MonthCalendar.css';

const maxDaysCalendar = 42;  

const Day = (props) => {
    const dispatch = useDispatch();
    const nowDate = new Date();

    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let divName = <div className="dayName">{(width>768) ? props.dayObj.name : props.dayObj.shortName}</div>;   
    let classDay = "col day";
    let dayNumber = "dayNumber";
    let styleDay = {
        flex: "0 0 auto" , 
        width: "14.285%" , 
        cursor: "pointer"
    }
    //let handleClickDay = null;

    if( props.dayObj.month !== props.currentDateTime.getMonth() )  {
        classDay+= " diffrentMonth";
    }
    if( (props.dayObj.day === nowDate.getDate()) && (props.dayObj.month === nowDate.getMonth() )  )  {
        styleDay.backgroundColor = '#9fbdff';
    }
    const handleClickDay = () => {
        let nD = new Date(props.dayObj.year , props.dayObj.month , props.dayObj.day);
        loadVisitsOfDayFromDB(nD).then(resolve => dispatch( setVisits(resolve) ) );        
        dispatch( setDate(nD) );
        dispatch( changeCalendar({kind: 2} ) );
        };
    return ( 
            <>
            <div    className={classDay} 
                    style={styleDay} 
                    onClick={handleClickDay} 
                    >
                    {props.dayObj.name !== "" && divName}
                    <div className={dayNumber}>{props.dayObj.day}</div>
            </div>
            </>
         );
    
}

const MonthCalendar = (props) => {

const InitCalendarData = ()=> {
    let tempObj = [];
    for(var i = 0 ;  i < 43 ; i++) {
        tempObj[i] = {
        id: i,
        day: 0 , 
        month: 0 ,
        year: 0 ,
        name: "" , 
        shortName: ""
        }
    }
    tempObj[1].name = "Poniedziałek";
    tempObj[2].name = "Wtorek" ;  
    tempObj[3].name = "Środa" ;  
    tempObj[4].name = "Czwartek" ;  
    tempObj[5].name = "Piątek" ;  
    tempObj[6].name = "Sobota" ;  
    tempObj[7].name = "Niedziela" ;  
    tempObj[1].shortName = "Pn";
    tempObj[2].shortName = "Wt" ;  
    tempObj[3].shortName = "Śr" ;  
    tempObj[4].shortName = "Cz" ;  
    tempObj[5].shortName = "Pt" ;  
    tempObj[6].shortName = "So" ;  
    tempObj[7].shortName = "Nd" ; 
    return tempObj;  
    }

const GetDaysInMonths = (month , year)=> {
    let ndays;
        if (month === 0 || month === 2 || month === 4 || month === 6 || month === 7 || month === 9 || month === 11)
        ndays = 31;
    else if (month === 2 || month === 5 || month === 8 || month === 10)
        ndays = 30;
    else {
        if (year % 4 !== 0)
            ndays = 28;
        if (year % 4 === 0) {
            if (year % 100 === 0 && year % 400 !== 0)
                ndays = 28;
            else
                ndays = 29;
        }
    }
    return ndays; 
}

const SetDayObjects = (month, year)=> {    
    //funkcja wyliczające ile dni ma miesiac 
    //this function count how days is in the month
    let ndays = GetDaysInMonths(month , year);
    //-----------------------------
    
    var startDay = new Date(year, month, 1);	//pobranie pierwszego dnia miesiąca bierzącego/przełącznonwgo
    var firstD = startDay.getDay(); // pobranie numeru dnia tygodnia
    if (firstD === 0)
        firstD = 7;	//jeśli niedziela to ma numer 7----na potrzeby kalendarza

    var tempCal = [];
    tempCal = InitCalendarData();  
    
    for (var i = 1; i <= ndays; i++) //wypełnianie kalendarza datami
    {
        var iDay = i + firstD - 1;
        tempCal[iDay].day = i;
        tempCal[iDay].month = month;
        tempCal[iDay].year = year;
    }    
    //wypełnianie do przodu
    for (i = 0; i <= maxDaysCalendar - ndays - firstD; i++)
    {
        iDay = ndays + firstD + i;
        tempCal[iDay].day = i+1;
        tempCal[iDay].month = month+1;
        let iY = ((month+1) > 11) ? year+1 : year
        tempCal[iDay].year = iY;
    }
    //wypełnianie do tyłu
    let _month = month-1;
    //month = month - 1;
    if (_month === -1)
        _month = 11;
    if (_month === 0 || _month === 2 || _month === 4 || _month === 6 || _month === 7 || _month === 9 || _month === 11)
        ndays = 31;
    else if (_month === 2 || _month === 5 || _month === 8 || _month === 10)
        ndays = 30;
    else {
        if (year % 4 !== 0)
            ndays = 28;
        if (year % 4 === 0) {
            if (year % 100 === 0 && year % 400 !== 0)
                ndays = 28;
            else
                ndays = 29;
        }
    }        
    startDay = new Date(year, _month , 1);	//pobranie pierwszego dnia miesiąca bierzącego/przełącznonwgo
    var backD = startDay.getDay(); // pobranie numeru dnia tygodnia
    if (backD === 0)
        backD = 7;	//jeśli niedziela to ma numer 7----na potrzeby kalendarza
    for (i = 0; i < firstD; i++)
    {
        iDay = ndays - firstD + i;
        tempCal[i].day = iDay + 1;
        tempCal[i].month = _month;
        let iY = (_month === 11) ? year-1 : year
        tempCal[i].year = iY;
    }
    return tempCal;   
}

const currentDateTime = useSelector(store => store.currentDateTime );

let dayObjects = SetDayObjects(currentDateTime.getMonth(), currentDateTime.getFullYear() );
    
return ( 
    <>
        {dayObjects.map( (day , index)=> {
            if(index === 0) return null;
            else return <Day 
                        key={day.id} 
                        dayObj={day} 
                        currentDateTime={currentDateTime}
                        handleChangeCalendar = {props.handleChangeCalendar }
                    />;
        } ) 
        }         
    </>
    );
}
 
export default MonthCalendar;