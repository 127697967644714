export const SET_VISITS_OF_DAY = 'SET_VISITS_OF_DAY';
export const CLEAR_VISITS = 'CLEAR_VISITS';

export const setVisits = ( visits ) => ({
    type: SET_VISITS_OF_DAY,
    payload: visits
  });

  export const clearVisits = ( ) => ({
    type: CLEAR_VISITS,
    payload: null
  });  
