import { SET_NET_STATUS } from '../actions/netStatusActions';
import { SET_FROZEN_APP } from '../actions/netStatusActions';
import { SET_UPDATING_STATUS } from '../actions/netStatusActions';
import {FREE} from '../customfunc';

export const netStatus = (state = {netStatus: true , frozenApp: false , updatingStatus: FREE }, action) => { 
    switch (action.type) { 
      case SET_NET_STATUS: {
        //let prevSt = initAppSettings;
        //initAppSettings.netStatus = action.payload;
        //initAppSettings.busyApp = state.busyApp;
       //return prevSt; 
       const _state = {
        netStatus: action.payload,
        frozenApp: state.frozenApp,
        updatingStatus: state.updatingStatus
       }
       return _state; 
      }
      
      case SET_FROZEN_APP: {
       const _state = {
        netStatus: state.netStatus,
        frozenApp: action.payload,
        updatingStatus: state.updatingStatus
       }
       return _state; 
      }

      case SET_UPDATING_STATUS: {
        const _state = {
         netStatus: state.netStatus,
         frozenApp: state.frozenApp,
         updatingStatus: action.payload
        }
        return _state; 
       }

      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }