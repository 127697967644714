export const CHANGE_KIND_CALENDAR = 'CHANGE_KIND_CALENDAR';
export const SET_NEW_CONSUMER = 'SET_NEW_CONSUMER';

//kindCalendar { 
//  kind: id
//  data: {
//          
//       }
// }
//kind : 1 - kalendarz miesięczny - 0001
//kind : 2 - kalendarz dzienny - 0010
//kind : 6 - dzienny - okno nowej wizyty - 0110
//kind : 10 - dzienny - okno edycji wizyty - 1010
//kind : 16 - dzienny pracownika - 1 0000
export const changeCalendar = ( kindCalendar ) => ({
    type: CHANGE_KIND_CALENDAR,
    payload: kindCalendar
  });


export const setNewConsumer = ( newConsumer ) => {
  return {
    type: SET_NEW_CONSUMER,
    payload: newConsumer
    }
  }; 
