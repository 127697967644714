import React , {useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import global and cuntom varibles or functions
import { loadVisitsOfDayFromDB , FREE , BUSY , appNetStatus } from '../customfunc';
import { webAddress } from '../global';

//import Redux Actions
import { nowDate , prevMonth , nextMonth , prevDay , nextDay , setDate } from '../actions/dateAction';
import { changeCalendar } from '../actions/kindCalendarActions';
import { setVisits } from '../actions/visitsActions';
import { setFrozenApp , setNetStatus } from '../actions/netStatusActions';

//import component
import MonthCalendar from './MonthCalendar';
import DayCalendarApp from './DayCalendarApp';
import YearCalendarApp from './YearCalendarApp';



//import css file
import './CalendarApp.css';

export const Months = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"]; 
export const Days = ['Niedziela' , 'Poniedziałek' , 'Wtorek' , 'Środa' , 'Czwartek' , 'Piatek' , 'Sobota' ];

const CalendarApp = (props) => {

const handleChageSelect=(e) => {
let val = e.target.value;
if(val === "day")   dispatch( changeCalendar( {kind: 2}) );
if(val === "month")  dispatch( changeCalendar( {kind: 1 }) );   
if(val === "year")  dispatch( changeCalendar( {kind: 4}) );  
selectOption = val;
}

//use dispatch
const dispatch = useDispatch();
//use selectors
const currentDateTime = useSelector(store => store.currentDateTime );
const kindCalendar = useSelector(store => store.kindCalendar);
const appSettings = useSelector(store => store.netStatus);

//let dayObjects = SetDayObjects(currentDateTime.getMonth(), currentDateTime.getFullYear() );
let handleNext = null;
let handlePrev = null;
let handleNowDay = null;
let calendarCurrentDateTime = null;
let selectOption = null;
let frozenButton = '';
//if(appSettings.updatingStatus === UPDATING ) frozenButton = 'frozenButton';
//if(appSettings.updatingStatus === FREE ) frozenButton = '';

if(kindCalendar.kind === 1) //monthly calendar
{
    //let date = new Date(currentDateTime); 
    calendarCurrentDateTime = Months[ currentDateTime.getMonth() ]  + " "  + currentDateTime.getFullYear(); //To co jest wyświetlane na pasku daty. W zalezności od rodzaju kalenendarza będą inne informacje
    handleNext = ()=>dispatch( nextMonth() ) ;
    handlePrev = ()=>dispatch( prevMonth() ) ;
    handleNowDay = ()=>dispatch ( nowDate() );
    loadVisitsOfDayFromDB( currentDateTime ).then(resolve => dispatch( setVisits(resolve) ) ); 
    //loadVisitsOfDayFromDB( currentDateTime ) ;
    selectOption = 'month';
}
if(((kindCalendar.kind === 2)) || kindCalendar.kind===32) //daily calendar or workewr calendar - kalendarz dzienny lub dzienny kalendarz pracownika
{
    calendarCurrentDateTime = Days[currentDateTime.getDay() ] + ", " + currentDateTime.getDate() + " " + Months[ currentDateTime.getMonth() ]  + " "  + currentDateTime.getFullYear();
    
    handleNext = ()=> {
        appNetStatus(BUSY); //zablokowanie applikacji aby zablokować cylkicznie - automatyczne pobieranie danych 
        //dla przyszłych poprawek w kodzie - appNetStatus musi być jako pierwsza czyność
        if(appSettings.frozenApp === false) {
            //if(appSettings.updatingStatus === FREE ) { //usuwamy - reason: zmiana daty musi mieć pierszeństwo. 
                dispatch( setFrozenApp ( true ) ) ;//wyświetlenie komunikatu ... Loading...
                let date = new Date( currentDateTime );  
                date.setDate(date.getDate()+1);
                dispatch( nextDay(date) ) ;
                const prom = loadVisitsOfDayFromDB( date );
                prom.then(resolve => {
                    if(typeof resolve === 'object' && (!Array.isArray(resolve)) && resolve !== null  ) {
                        if(resolve.session && (resolve.session !== undefined) ) {
                            if(resolve.session.status) { 
                            dispatch( setNetStatus(true) ); //brak błędów sieci
                            resolve.session.status === 'notLogged' && (document.location.href=webAddress);  
                            }  
                        }
                        if(resolve.error) {  
                            dispatch( setNetStatus(false) );
                            appNetStatus(FREE); 
                        }
                    }

                    if(Array.isArray(resolve)) {
                    dispatch( setVisits(resolve) ) ; 
                    dispatch( setFrozenApp ( false ) ) ;
                    appNetStatus(FREE);
                    }
                });
                
                prom.catch( (err)=> {
                    console.log(err);
                    appNetStatus(FREE);
                })
           // }
        }
    }
    handlePrev = ()=> {
        appNetStatus(BUSY); //zablokowanie applikacji aby zablokować cylkicznie pobieranie danych 
        if(appSettings.frozenApp === false) {
            //dla przyszłych poprawek w kodzie - appNetStatus musi być jako pierwsza czyność
            dispatch( setFrozenApp ( true ) ) ;
            let date = new Date(currentDateTime);  
            date.setDate(date.getDate()-1);
            dispatch( prevDay(date) ) ;
            const prom = loadVisitsOfDayFromDB( date );
            prom.then(resolve => {
                if(typeof resolve === 'object' && (!Array.isArray(resolve)) && resolve !== null  ) {
                    if(resolve.session && (resolve.session !== undefined) ) {
                        if(resolve.session.status) {
                        dispatch( setNetStatus(true) );
                        resolve.session.status === 'notLogged' && (document.location.href=webAddress);  
                        }  
                    }
                    if(resolve.error) {  
                        dispatch( setNetStatus(false) );
                        appNetStatus(FREE); 
                    }
                }

                if(Array.isArray(resolve)) {
                dispatch( setVisits(resolve) );
                dispatch( setFrozenApp ( false ) ) ;  
                appNetStatus(FREE);
                }
            });
            prom.catch( (err)=> {
                console.log(err);
                appNetStatus(FREE);
            })
            }
        }
    handleNowDay = ()=> {
        appNetStatus(BUSY); //zablokowanie applikacji aby zablokować cylkicznie pobieranie danych 
        if(appSettings.frozenApp === false) {
            dispatch( setFrozenApp ( true ) ) ;
            let date = new Date();  
            dispatch( nowDate() ) ;
            const toodayPromice = loadVisitsOfDayFromDB( date );
            toodayPromice.then(resolve => {
                if(typeof resolve === 'object' && (!Array.isArray(resolve)) && resolve !== null  ) {
                    if(resolve.session && (resolve.session !== undefined) ) {
                        if(resolve.session.status) {
                            dispatch( setNetStatus(true) );
                            resolve.session.status === 'notLogged' && (document.location.href=webAddress);  
                        } 
                    }
                    if(resolve.error) {  
                        dispatch( setNetStatus(false) );
                        appNetStatus(FREE); 
                    }
                }
                if(Array.isArray(resolve)) {
                    dispatch( setVisits(resolve) );
                    dispatch( setFrozenApp ( false ) ) ;
                    appNetStatus(FREE);
                    }
            }); 
            toodayPromice.catch( (err)=> {
                console.log(err);
                appNetStatus(FREE);
            });

            }        
        }
    selectOption = 'day';
   
}

if( kindCalendar.kind === 4) //year calendar
{
    
    calendarCurrentDateTime = currentDateTime.getFullYear();
    handleNext = ()=> {
        let date = new Date( currentDateTime );  
        date.setFullYear(date.getFullYear()+1);
        dispatch( setDate(date) ) ;
        }
    handlePrev = ()=> {
        let date = new Date(currentDateTime);  
        date.setFullYear(date.getFullYear()-1);
        dispatch( setDate(date) ) ;
        }
    handleNowDay = ()=> {
        //let date = new Date();  
        dispatch( nowDate() ) ;       
        }
    selectOption = 'year';
    
}

const isDayCalendarAllWorkers=()=> {
   if( kindCalendar.kind === 2 || kindCalendar.kind === 10 || kindCalendar.kind === 18 ) return true;
   else return false;   
} 

const selectStyle = {
    height: 'auto',
    padding: '2px 6px'
}

//useEffects
 useEffect( ()=> {
    //dayObjects = SetDayObjects(currentDateTime.getMonth(), currentDateTime.getFullYear() ); 
    } , [] );
           return (           
           <>
              <div className="col-md-10"> 
                <div id="center_right">
                    <div id="cal_month">
                    <div style={{borderLeft: "1px solid black"}}> 
                            <div className='topMenuCal'>
                                <div className='manBtns'>    
                                    <div className='row manBtnsA'>
                                        <div className='col-9 elmMenuLeft'>
                                            <div className='boxBtn'>
                                                <div onClick={ handleNowDay } className='app_btn1'>Dzisiaj</div>
                                            </div>
                                            <div className='boxBtn'>
                                                <div className={`left_click app_btn1 ${frozenButton}`}  onClick={handlePrev}>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                                    </svg>      
                                                </div>
                                            </div>

                                            <div className='boxBtn'>
                                                <div className="right_click app_btn1" onClick={handleNext}>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className='boxBtn'>
                                                <div className="DateTime spanText1" >{calendarCurrentDateTime}</div>
                                            </div>
                                        </div>
                                        <div className='col-3 elmMenuRight'>
                                            <div className='boxSelectBox'>
                                                <div className='selectBox'>                                    
                                                    <select onChange={handleChageSelect}
                                                        className="calSelect form-select" 
                                                        style={selectStyle}
                                                        aria-label="Default select example"
                                                        value = {selectOption} >
                                                            <option value="day">Dzień</option>
                                                            <option value="month">Miesiąc</option>
                                                            <option value="year">Rok</option>
                                                    </select>
                                                </div>  
                                            </div>
                                        </div>
                                </div> 
                            </div>          
                            <div style={{clear:"both"}}></div>    

                            </div>
                            <div className='mainSceneCalesdars'>
                                <div style={{width: "94%" , margin: "0px auto"}}>
                                    <div className='mainSceneCal'>
                                        <div className="row noGutter">
                                        
                                            { (kindCalendar.kind === 1) && <MonthCalendar 
                                            />   }
                                            { isDayCalendarAllWorkers() && <DayCalendarApp  //if kindCalendar is 2 than true
                                            />    }
                                            { (kindCalendar.kind === 4) && <YearCalendarApp 
                                                currentDateTime = {currentDateTime}
                                            />    }
                                        </div>
                                    </div>        
                                </div> 
                            </div>      
                            </div>                   
                    </div>
                </div>
              </div>
           </> 
         );
    
}
 
export default CalendarApp;