import { SET_VISITS_OF_DAY , CLEAR_VISITS  } from '../actions/visitsActions';

export const visits = (state = null, action) => { 
    switch (action.type) { 
      case  SET_VISITS_OF_DAY: {
        return action.payload;
      }

      case  CLEAR_VISITS: {
        return null;
      }
       
      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }