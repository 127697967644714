import React, {useEffect} from 'react';
//import { useDispatch , useSelector} from 'react-redux';
//import { loadLoggedUser } from '../actions/userActions';
import { logging } from '../customfunc';

import './Login.css';

const Login =(props)=> { 
//const user = useSelector(store => store.user);
//const dispatch = useDispatch;

const handeleLogin=()=>{
const login = document.getElementById('loginInput').value;
const pass = document.getElementById('passwordInput').value;
logging(login , pass).then((json)=>props.handleChangeSite(json)).catch((e)=>alert(e));
return true;
}

useEffect( () => {
  } , [] );

return(
    <>
    <div className='mainLoginPage'>
        <div className="login-page">
            <div className="form">
                <form className="login-form">
                <input id="loginInput" type="text" placeholder="username"/>
                <input id="passwordInput" type="password" placeholder="password"/>
                <div className='btnLogin' onClick={handeleLogin} >login</div>
                </form>
            </div>
        </div>
    </div>
    </>
);
}

export default Login;