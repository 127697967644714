import React , {useEffect, useState} from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { loadConsumers } from '../actions/consumersActions';

import { loadConsumersFromDB } from '../customfunc';

import "./ConsumersApp.css";
import './App.css';
import AddNewConsumer from "./AddNewConsumer";
import DeleteConsumer from "./DeleteConsumer";

let type = null; 
let consumer = null; //obiekt - pojedyńczy konsument
//let consumers = null; // globalna tablica wszystkich konsumentów *w zasadzie są dwie... ta i currentConsumers w useState


const ConsumersList =(props)=> {

    let conList =  props.obj.map( val => ( 
        <div className="consumerMainBox" key={val.id}> 
            <div id="Box" className="consumerBox"> 
                <div className="row">   
                    <div className="col-6">
                        <div style={{margin:"5px"}}>
                            Numer klienta: {val.id}
                        </div>
                        <div style={{margin:"5px"}}>
                            Imię Nazwisko:<b> {val.name} {val.surname}</b>
                        </div>
                        <div style={{margin:"5px"}}>Numer telefonu: {val.phone}</div>
                    </div>
                    <div className="col-6 consumerRightMenuPos" >
                        <div className='row'>  
                        <div className='col-4 consumerMenuButtons'>
                            <button onClick={()=>props.handlePreviewConsumer(val)} className="btn btn-outline-dark btn-sm btn-main consumerBtn mb-1" type="button">Podgląd</button>    
                            <button onClick={()=>props.handleEditConsumer(val)} className="btn btn-outline-dark btn-sm btn-main mb-1" type="button">Edycja</button> 
                            <button onClick={()=>props.handleRemoveConsumer(val)} className="btn btn-outline-dark btn-sm btn-main mb-1" type="button">Usuń</button> 
                        </div> 
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    ) 
    );
    return (
        <>
        {conList}
        </>
    ); 
}

const ConsumersApp = () => {
const dispatch = useDispatch();

const consumers = useSelector(store => store.consumers);

const [bVisibleNewMenuApp , setVisibleNewMenuApp] = useState(false);
const [bRemoveConsumerApp ,  setRemoveConsumerApp] = useState(false); 
const [currentConsumers , setCurrentConsumers] = useState(null) ;  //local consumers store 

useEffect( ()=> {
setCurrentConsumers(consumers); 
 } ,[consumers]  )


 //tworzenie nowego klienta
 const handleAddNewConsumer=()=> {
consumer = null;     
type = "newConsumer";   
setVisibleNewMenuApp( !bVisibleNewMenuApp ); 
 }

//edit a client
 const handleEditConsumer = (val)=> {
 consumer = val;
type = "editConsumer";
setVisibleNewMenuApp( !bVisibleNewMenuApp ); 
    }  

//preview a client
const handlePreviewConsumer = (val)=> {
  consumer = val;
  type = "previewConsumer";
  setVisibleNewMenuApp( !bVisibleNewMenuApp ); 
  }   

  //Wyszukiwanie klientów 
 const getConsumers=(text)=>{
    let foundConsumers = consumers.filter( val => (val.name.toLowerCase().includes(text.toLowerCase()) || val.surname.toLowerCase().includes(text.toLowerCase()) || val.phone.toLowerCase().includes(text.toLowerCase()) ));
       return foundConsumers;    
 }
   
 const handleChangeValue=(e)=> {
    let cons = getConsumers(e.target.value) ;  
    setCurrentConsumers(cons); 
   }

const clearFindTextBox=()=>{
    document.getElementById('formGroupExampleInput').value='';
    setCurrentConsumers(consumers); 
   }
 const handleRemoveConsumer=(val)=> {
    consumer = val;
    setRemoveConsumerApp(!bRemoveConsumerApp);
 }  


 const refreshConsumers=()=> {
    //load consumers from DB and write to global store redux
    loadConsumersFromDB().then((json)=> dispatch(loadConsumers(json)));
 }
    return ( 
        <>
        <div className="col-md-10">
            <div className="consumerMenuBar" >
                <div className='consumerTopMenuDiv1'>
                            <div style={{marginRight:"20px"}}>
                                <button onClick={handleAddNewConsumer} className="btn btn-outline-dark btn-sm btn-main" type="button">Dodaj klienta</button>
                            </div>
                            
                            <div style={{marginRight:'20px' , width: '300px'}} >
                                <div className="form-group" style={{display:'flex' , alignItems:'center'}}>
                                    <label htmlFor="formGroupExampleInput" style={{marginRight:'10px'}}>Szukaj: </label>
                                    <input onChange={handleChangeValue} type="text" className="form-control" id="formGroupExampleInput" placeholder="imię, nazwisko, telefon"  />
                                </div>
                            </div>

                            <div style={{marginRight:"5px"}}>
                                <button onClick={clearFindTextBox} className="btn btn-outline-dark btn-sm btn-main" type="button">Wyczyść</button>
                            </div>
                </div>
            </div>
            
             {currentConsumers && <ConsumersList 
                    obj={currentConsumers} 
                    handleEditConsumer={handleEditConsumer} 
                    handlePreviewConsumer={handlePreviewConsumer}
                    handleRemoveConsumer={handleRemoveConsumer}
                    /> }
             {bVisibleNewMenuApp && <AddNewConsumer 
                    actionType={type} 
                    handleAddNewConsumer={handleAddNewConsumer} 
                    consumer={consumer}
                    refreshConsumers={refreshConsumers } 
                    /> }

            {bRemoveConsumerApp && <DeleteConsumer 
                    handleRemoveConsumer={handleRemoveConsumer} 
                    refreshConsumers={ refreshConsumers } 
                    consumer={consumer}
                    /> }

        </div>
        </>
     );
}
 
export default ConsumersApp;