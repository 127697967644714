import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';

import Login from './Login';
import Main from './Main';
import { loadLoggedUser } from '../actions/userActions';
import { setNetStatus } from '../actions/netStatusActions';
import { isSession } from '../customfunc';

//import { createContext } from 'react/cjs/react.production.min';

const App =()=> { 
const user = useSelector(store => store.user);
const dispatch = useDispatch();

useEffect( () => {
isSession().then((json)=>{
if(json !== undefined) {
  dispatch(setNetStatus(true))
  dispatch(loadLoggedUser(json));
  }
});

let img0 = new Image();
img0.src = 'images\\network_error.png';
let img1 = new Image();
img1.src = 'images\\network_ok.png';
} , [] );

const loggedSwitch=(data)=>{

  dispatch(setNetStatus(true))
  dispatch(loadLoggedUser(data));
}

let stat = null;
if(user !==null && (typeof(user)==='object')) {
  if(user.session) {
    if(user.session.status === 'logged') stat = user.session.status;
    if(user.session.status === 'notLogged') stat = user.session.status;
  }
}
return (
      <>
      {stat==='notLogged' && <Login handleChangeSite={loggedSwitch}/> }
      {stat==='logged' && <Main  /> }
      </>
    );
}

export default App;


