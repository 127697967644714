import React from 'react';
import { useSelector } from 'react-redux';

import MenuApp from './MenuApp';
import CalendarApp from './CalendarApp';
import WorkersApp from './WorkersApp';
import ConsumersApp from './ConsumersApp';
import SettingsApp from './SettingsApp';
import './Central.css';



const CentralApp = () => {
const bVisibleMenu = useSelector(store => store.bVisibleMenu);
const currentVisibleSite = useSelector(store => store.currentVisibleSite);

return ( 
<>
<div className='central'>
    <div className="row elmCentral">
        {bVisibleMenu && <MenuApp /> }  
        {currentVisibleSite===1  && <CalendarApp /> }
        {currentVisibleSite===4 && <WorkersApp /> }   
        {currentVisibleSite===5 && <ConsumersApp /> }              
        {currentVisibleSite===7 && <SettingsApp /> }   
    </div>
</div>
</>
);
}
 
export default CentralApp;

