import React from 'react';

import "./AddNewConsumer.css";
export const REMOVE_VISIT = "REMOVE_VISIT";
export const REMOVE_WORKER = "REMOVE_WORKER";

//czy menu ma statyczne? czy pływające
const staticMenu = true;

const DialogYesAndNo = (props) => {

let pos = {x: 0 , y: 0};  
let mMove = false;    

const handleMouseDown=(e)=>
{
    if(!staticMenu) {
        let mainBar = document.getElementById('dialogYesAndNoMainBar');
        mainBar.style.position = 'absolute';
        let xFar = e.pageX - mainBar.offsetLeft ;
        let yFar = e.pageY - mainBar.offsetTop ;
        pos = { 
            x: xFar,
            y: yFar
        }
        mMove = true;
    }
}  

const handleMouseUp=(e)=>
{
    mMove = false;
} 

const handlerMouseMove=(ex)=>
{
    if(mMove) {
        let mainBar = document.getElementById('dialogYesAndNoMainBar');
        mainBar.style.left = `${ex.pageX-pos.x}px`; 
        mainBar.style.top = `${ex.pageY-pos.y}px`;
        }
}

window.addEventListener('mouseup' , ()=>{ 
mMove = false;
})   

let titleBar = props.titleBar;
let question = props.question;

return ( 
     <> 
            <div className='bgOpenMenu' style={{zIndex: 1000}}></div>
             <div className="openMenu" style={{zIndex: 1001}}>
                <div className="row-1">
                    
                        <div id="dialogYesAndNoMainBar" className="mainBarDialogBox" onMouseMove={handlerMouseMove}>
                            <div id="dialogYesAndNoTopBar" className="topBar" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                                <p style={{textAlign: "left"}}>{titleBar}</p>
                            </div>
                            <div className="centralBar">
                            {question}
                            </div>
                            <div className="bottomBar">
                                <div className="buttons_main">
                                <button type="button" className="btn btn-dark btn-sm button-make" onClick={()=>props.handleButtonYes(props.idVisit)}>Tak</button>             
                                <button type="button" className="btn btn-dark btn-sm button-close" onClick={()=>props.handleButtonNo()}>Nie</button>
                                </div></div>
                        </div>
                    
                    
                </div>
            </div>
     </>   
     );
}
 
export default DialogYesAndNo;