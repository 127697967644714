export const VISIBLE = 'VISIBLE';
export const NONE = 'NONE';

export const bVisibleMenu = ( bVisibleMenu ) => ({
    type: VISIBLE,
    payload: bVisibleMenu
  });


export const noneVisibleMenu = ( bVisibleMenu ) => ({
    type: NONE,
    payload: bVisibleMenu
  });