export const NOW_DATE = 'NOW_DATE';
export const SET_DATE = 'SET_DATE';
export const PREV_MONTH = 'PREV_MONTH';
export const NEXT_MONTH = 'NEXT_MONTH';
export const PREV_DAY = 'PREV_DAY';
export const NEXT_DAY = 'NEXT_DAY';

export const nowDate = () => ({
    type: NOW_DATE
  });

export const setDate = (newData) => ({
    type: SET_DATE , 
    payload: newData
  });

export const prevMonth = (  ) => ({
  type: PREV_MONTH
});  

export const nextMonth = (  ) => ({
  type: NEXT_MONTH
}); 

export const prevDay = ( date ) => ({
  type: PREV_DAY ,
  payload: date
}); 

export const nextDay = ( date ) => ({
  type: NEXT_DAY ,
  payload: date
}); 