import React , { useState, useEffect } from 'react';

export const ClockComponent = () => {
const [timeText , setTimeText ] = useState(()=> {
    const time = new Date();    
    return `${time.getHours()<10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes()<10 ? `0${time.getMinutes()}` : time.getMinutes()}:${time.getSeconds()<10 ? `0${time.getSeconds()}` : time.getSeconds()}` ;    
});  

const setClock = ( ()=>{
    setInterval(()=> {
        const time = new Date();   
        setTimeText( `${time.getHours()<10 ? `0${time.getHours()}` : time.getHours()}:${time.getMinutes()<10 ? `0${time.getMinutes()}` : time.getMinutes()}:${time.getSeconds()<10 ? `0${time.getSeconds()}` : time.getSeconds()}` );
    } , 1000 )
} )

useEffect( ()=> {
    setClock();
}, [])

    return ( 
     <>
      <div>
      <span><b>{timeText}</b></span>    
      </div>  
     </>   
    

     );
}
 
