import React, { useState } from 'react';

import {useDispatch , useSelector} from 'react-redux';

import { setWorkers } from '../actions/workersActions';
import { workers } from '../reducers/workersReducers';

import { urlAppFile } from '../global';
import { loadWorkersFromDB } from '../customfunc';
import DialogYesAndNo from './DialogBoxComponent';

import "./WorkersApp.css";
import './SettingsApp.css';
import './ConsumersApp.css';
import './AddNewConsumer.css';
import './App.css';

const staticMenu = true;

let pos = {x: 0 , y: 0};  
let mMove = false;

const NEW = 'NEW';
const EDIT = 'EDIT';
let kindAddWorkerMenu = null;


const Workers = (props) => {

  const WorkerAddMenu = (props) => {

    const handleMouseDown=(e)=>
    {
    if(!staticMenu) {
      let mainBar = document.getElementById('mainBarWorker');
      mainBar.style.position = 'absolute';
      let xFar = e.pageX - mainBar.offsetLeft ;
      let yFar = e.pageY -mainBar.offsetTop ;
      pos = { 
          x: xFar,
          y: yFar
      }
      mMove = true;
    }
    }  
  
    const handleMouseUp=(e)=>
    {
        mMove = false;
    } 
  
    const handlerMouseMove=(ex)=>
    {
        if(mMove) {
            let mainBar = document.getElementById('mainBar');
            mainBar.style.left = `${ex.pageX-pos.x}px`; 
            mainBar.style.top = `${ex.pageY-pos.y}px`;
            }
    }  
  
    const handle1Button = (action)=> {
      
      let id = document.getElementById('idWorkerTextBox').value;
      let nameAndSurname = document.getElementById('nameAndSurname').value;
      let per1 = document.getElementById('inlineCheckbox1').checked;//admin 
      let per2 = document.getElementById('inlineCheckbox2').checked;//właściciel
      let per3 = document.getElementById('inlineCheckbox3').checked;//pracownik
      //let per4 = document.getElementById('inlineCheckbox4').checked;//klient
      let perrmision = 0;
      if(per1) perrmision += 64;
      if(per2) perrmision += 32;
      if(per3) perrmision += 2;
      //if(per4) perrmision += 1;
      let shortName = document.getElementById('shortName').value;
      let colorWorker = document.getElementById('workerColor').value;
      let activeWorker = document.getElementById('checkBoxActiveWorker').checked;
      let visibleWorker = document.getElementById('checkBoxVisibleWorker').checked;
      let act = '';
      if(action === NEW) act = "saveNewWorker";
      if(action === EDIT) act = "editWorker";
    
      if(nameAndSurname === '' ) {
          alert('Imię lub nazwisko musi być wypełnione!');
          return false;
          }
      if(shortName === '' ) {
        alert('Krótka nazwa musi być wypełnione!');
        return false;
        }    
      
        var objCon = { 
          Action: act ,
          id,
          name: nameAndSurname,
          shortName,
          perrmision,
          colorWorker,
          activeWorker,
          visibleWorker
        };
        let urlData = new URLSearchParams();
        urlData.append('var1', JSON.stringify(objCon) );  
        fetch(urlAppFile , {
            method: 'POST' ,  
            body: urlData  
            })
            .then(response => response.text())
            .then(text => {
              if(text=== 'success' ) {
                props.handleCloseMenu(); 
                loadWorkersFromDB()
                .then(json => {
                dispatch( setWorkers(  json ) ); 
                }
                )
              }
              else {
                alert('brak możliwości zapisu');
              }
            }
            ) 
              
      
    }
  
    const handle2Button = ()=> {
    props.handleCloseMenu();  
    }

    const handleRandomColorChoise = (render=false)=> {
    let aCol = [];
    while(aCol.length < 6) {   
      let iRand = Math.round( 15 *  Math.random() ); 
      if(iRand === 10 ) iRand = 'A';
      if(iRand === 11 ) iRand = 'B';
      if(iRand === 12 ) iRand = 'C';
      if(iRand === 13 ) iRand = 'D';
      if(iRand === 14 ) iRand = 'E';
      if(iRand === 15 ) iRand = 'F';
      aCol.push(iRand);  
    }
    aCol = '#' + aCol.join( '' ) ;
    if(render) document.getElementById("workerColor").value = aCol;
    return aCol;
    }
    const handleChangeActivUser = ()=> {
    }

    const handleChangeVisibleUser = ()=> {
    }
    
    
    //----------------------------------------------------------to render
    let nameTitleBar = '';
    if(props.kindAction === NEW ) nameTitleBar = 'Dodawanie pracownika';   
    else if(props.kindAction === EDIT) nameTitleBar = "Edytowanie pracownika";
    let defaultColor =  handleRandomColorChoise ();
    const nameButton1 = "Zapisz";
    let arrWork = null;
    let nameSurnm = '';
    let shortName = '';
    let perrmision = 0;
    let act = true;
    let vis = true;
    if(props.kindAction === EDIT) {
      arrWork = workers.filter( val => val.id === visitMenuAddWorker.idWorker);
      if( arrWork.length === 1 ) {
        nameSurnm = arrWork[0].namesurname;
        shortName = arrWork[0].short_name;
        defaultColor = arrWork[0].color;
        perrmision =  parseInt(arrWork[0].perrmision);     
        act = parseInt(arrWork[0].active); 
        vis = parseInt(arrWork[0].visible); 
        }
      }
    

    return (
     <>
     <div className='bgOpenMenu'></div>
     <div className="openMenu">
              <div className="row-1">
                      <div id="mainBar" className="mainBarWorker" onMouseMove={handlerMouseMove}>
                          <div id="topBar" className="topBar" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                              <p style={{textAlign: "left"}}>{nameTitleBar}</p>
                          </div>
                          <div className="centralBar">
                              <div className='general_grey mt-4'>
                              <input id='kindActionTextBox' defaultValue= {props.kindAction} disabled style={{display: "none"}} ></input>
                              <input id='idWorkerTextBox' defaultValue= {visitMenuAddWorker.idWorker} disabled style={{display: "none"}}></input>
                              <label className='generalLabel_grey' >Personalia</label>
                                <div className="input-group mb-3">
                                <span className="input-group-text" id="inputNameAndSurname">Imię i nazwisko</span>
                                <input type="text" id="nameAndSurname" className="form-control" aria-label="Sizing example input" aria-describedby="inputNameAndSurname" placeholder="Imię i nazwisko" defaultValue={nameSurnm}/>
                                </div>

                                <div className="input-group mb-3">
                                <span className="input-group-text" id="inputShortName">Krótka nazwa</span>
                                <input type="text" id="shortName" className="form-control" aria-label="Sizing example input" aria-describedby="inputShortName" placeholder="krótka nazwa" defaultValue={shortName}/>
                                </div>

                              </div>

                              <div className='general_grey mt-4'>
                              <label className='generalLabel_grey' >Wybór roli</label>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultChecked={ (perrmision & 64) ? true : false} disabled/>
                                <label className="form-check-label" forhtml="inlineCheckbox1">Administrator</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" defaultChecked={ (perrmision & 32) ? true : false }/>
                                <label className="form-check-label" forhtml="inlineCheckbox2">Właściciel</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" defaultChecked={ (perrmision & 2) ? true : false } />
                                <label className="form-check-label" forhtml="inlineCheckbox3">Pracownik</label>
                              </div>
                              {/*<div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox4" defaultChecked={ (perrmision & 1) ? true : false } />
                                <label className="form-check-label" forhtml="inlineCheckbox4">Klient</label>
                                </div> */}                             
                              </div>

                              <div className='general_grey mt-4'>
                              <label className='generalLabel_grey' >Wybór koloru</label>
                                      <label forhtml="workerColor" className="form-label"><h6>kolor będzie widoczny np. w kalendarzu</h6></label>
                                      <div style={{display: 'flex'}}>
                                      <input type="color" className="form-control form-control-color" id="workerColor" defaultValue={defaultColor} title="Choose your color" />
                                      <button type="button" className="btn btn-dark btn-sm button-make" style={{marginLeft: "2em"}} onClick={()=>handleRandomColorChoise(true)}>Wylosuj kolor</button>
                                      </div>
                              </div>

                              
                              <div className='general_grey mt-4'>
                              <label className='generalLabel_grey' >Ustawienia użytkownika</label>
                                  <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="checkBoxActiveWorker" onChange={handleChangeActivUser} defaultChecked= { act?true:false }/>
                                    <label className="form-check-label" forhtml="checkBoxActiveWorker">Użytkownik aktywny</label>
                                  </div>
                                  <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="checkBoxVisibleWorker" onChange={handleChangeVisibleUser} defaultChecked={ vis?true:false}  />
                                    <label className="form-check-label" forhtml="checkBoxVisibleWorker">Użytkownik widoczny</label>
                                  </div>  
                              </div>

                              <div style={{clear:"both"}}></div>
                          </div>
                          <div className="bottomBar">
                              <div className="buttons_main">
                              <button type="button" className="btn btn-dark btn-sm button-make" onClick={()=>handle1Button(props.kindAction)}>{nameButton1}</button>                  
                              <button type="button" className="btn btn-dark btn-sm button-close" onClick={handle2Button}>Zamknij</button>
                              </div></div>
                      </div>
              </div>
          </div>
     </> 
    )
  }
  
const dispatch = useDispatch();    
const workers = useSelector( store => store.workers );  
const addWorkerState = {
  visible: false,
  kindAction: 'NULL' ,
  idWorker: null
}
const [ visitMenuAddWorker , setVisitMenuAddWorker] = useState(addWorkerState);

const removeWorkerState = {
  visible: false,
  idWorker: '',
  name: ''
}
const [ removeWorkerAskMenu , setRemoveWorkerAskMenu] = useState(removeWorkerState);

const handleAddNewWorker=()=> {
  kindAddWorkerMenu = NEW;  
  setVisitMenuAddWorker({
    visible: true,
    kindAction: NEW,
    idWorker: null
  });
}

const handleEditWorker=(idWorker)=> {
  kindAddWorkerMenu = EDIT;  
  setVisitMenuAddWorker({
    visible: true,
    kindAction: EDIT,
    idWorker 
  });
  }

const handleRemoveWorker=(idWorker)=> {
  const arrWork = workers.filter( val=> val.id === idWorker );
  let name = '';
  if(arrWork.length === 1 ) name = arrWork[0].namesurname;
  setRemoveWorkerAskMenu({
      visible: true,
      idWorker,
      name
    })
}  

const handleBtnYesRemoveWorker=()=> {
  const arrWork = workers.filter( val=> val.id === removeWorkerAskMenu.idWorker );
  let name = '';
  if(arrWork.length === 1 ) name = arrWork[0].namesurname;
  let idWorker = removeWorkerAskMenu.idWorker;

  let objCon = { 
    Action: 'removeWorker' ,
    name,
    id: idWorker
  };
  let urlData = new URLSearchParams();
  urlData.append('var1', JSON.stringify(objCon) );  
  fetch(urlAppFile , {
      method: 'POST' ,  
      body: urlData  
      })
      .then(response => response.text())
      .then(text => {
        if(text=== 'success' ) {
          handleBtnNoRemoveWorker();
          loadWorkersFromDB()
          .then(json => {
          dispatch( setWorkers(  json ) ); 
          }
          )
        }
        else {
          alert('brak możliwości usunięcia');
        }
      }
      ) 
}

const handleBtnNoRemoveWorker=()=> {
  setRemoveWorkerAskMenu({
    visible: false,
    idWorker: null,
    name: ''
  })
}

const handleCloseMenu=()=> {
  kindAddWorkerMenu = null;  
  setVisitMenuAddWorker({
    visible: false,
    kindAction: "NULL",
    idWorker: null
  });
  }
  
  const wList = workers.map((val , index)=> ( 
     <div key={val.id} className='workerMainBox'>
     <div id="iWorker" className="worker_main" >
        <div className='row'>
          <div className='col-6'>
            {/*<h6>ID: <span id="idWorker">{val.id}</span></h6>*/}
            <input id='idWorkerEditBox' defaultValue={val.id} disabled style={{display: 'none'}}/>
            <input id='nameSurnameEditBox' defaultValue={val.namesurname} disabled style={{display: 'none'}}/>
            <span className='workerNameInput'>Imię Nazwisko: <b>{val.namesurname}</b></span>
          </div>

          <div className='col-6 workerRightMenu'>
            <button onClick={()=>handleEditWorker(val.id)} className="btn btn-outline-dark btn-sm btn-main workerBtn mb-1" type="button">Edytuj</button>
            <button onClick={()=>handleRemoveWorker(val.id)} className="btn btn-outline-dark btn-sm btn-main workerBtn" type="button">Usuń</button>
          </div>
        </div>
      </div>
    </div>
    
  )
  ); 
  return (
  <div className="mb-3">
            <div className="workersMenuBar" >
            <button onClick={handleAddNewWorker} className="btn btn-outline-dark btn-sm btn-main" type="button">Dodaj Pracownika</button>
            </div>  
  {wList}
  {visitMenuAddWorker.visible && <WorkerAddMenu handleCloseMenu={handleCloseMenu} kindAction={kindAddWorkerMenu} /> }
  {removeWorkerAskMenu.visible && <DialogYesAndNo 
  titleBar={'Potwierdzenie'} 
  question={`Czy chcesz usunąć uzytkownika ${removeWorkerAskMenu.name}?`} 
  handleButtonYes={handleBtnYesRemoveWorker} 
  handleButtonNo={handleBtnNoRemoveWorker}  /> }
  </div> 
  
      
  )
  }

const WorkersApp=()=> {

    return ( 
      <>
      <div className="col"> 
      {workers && <Workers/> }      
      </div>
      </>  
     );

}
 
export default WorkersApp;