import { LOGIN_USERS } from '../actions/userActions';
import { SET_NET_STATUS } from '../actions/userActions';

const initialState = {
  session: {
    status: 'notLogged'
  } ,
  netStatus: false ,
  status : false 
};

export const user = (state = initialState , action) => { // (1)
    switch (action.type) { // (2)
      case LOGIN_USERS: {
       return action.payload; 
      }
      
      case SET_NET_STATUS : {
        let prevSt = state;
        prevSt.netStatus = action.payload;
        return prevSt;
      }

      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }