import React, { useEffect } from 'react';
import { useDispatch , useSelector } from 'react-redux';

import { nowDate } from '../actions/dateAction';
import { getPointsSMSApi, loadConsumersFromDB , loadSettingsFromDb , loadVisitsOfDayFromDB , loadWorkersFromDB } from '../customfunc';

import { loadConsumers } from '../actions/consumersActions';
import { loadWorkers } from '../actions/workersActions';
import { setVisits } from '../actions/visitsActions';
import { loadSettings, setPoints } from '../actions/settingsActions';

import './Main.css';
import Top from './Top';
import Central from './Central';

//import { createContext } from 'react/cjs/react.production.min';

const Loading=()=> {
  return(
    <>
    <div className='loadingMainBox'>
      <div className='loadingDivBox'>
        <div className='loadingTextBox'>
          <div className='loadingText'>Loading ...</div>
        </div>
      </div>
    </div>
    </>
  )
}

const NetError=()=> {
  return(
    <>
    <div className='errorMainBox'>
      <div className='errorDivBox'>
        <div className='errorTextBox'>
          <div className='errorText'>Błąd sieci...</div>
        </div>
      </div>
    </div>
    </>
  )
}

const Main =()=> {
const currentDateTime = useSelector(store => store.currentDateTime);
const consumers = useSelector(store => store.consumers);
const workers = useSelector(store => store.workers);
const settings = useSelector(store => store.settings);
const appSettings = useSelector(store => store.netStatus);
const dispatch = useDispatch();

const isLoading=()=> {
if(settings !== null && currentDateTime !==null && consumers!== null && workers!==null )  return true;
else return false;
}   

useEffect( () => {
loadSettingsFromDb().then((json)=>dispatch(loadSettings(json[0])) );  
dispatch( nowDate() );
loadConsumersFromDB().then((json)=> dispatch( loadConsumers(json) ) );
loadWorkersFromDB().then((json) => dispatch( loadWorkers(  json ) ));
loadVisitsOfDayFromDB().then((json)=>dispatch(setVisits(json)));
getPointsSMSApi().then((json)=> dispatch(setPoints(json.points)));
} , [] );


    return (
      <>
      <div>
        { appSettings.frozenApp && <Loading /> }
        { !appSettings.netStatus && <NetError /> }
        {false && <div className='alertInfoMainBox'><div className='alertInfoFixed'>aaaa</div></div>}
      </div>
      <div >
            <Top />
            {isLoading() && <Central  /> }
      </div>
      </>
    );
  
}

export default Main;


