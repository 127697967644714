export const SET_NET_STATUS = 'SET_NET_STATUS';
export const SET_FROZEN_APP = 'SET_FROZEN_APP';
export const SET_UPDATING_STATUS = 'SET_UPDATING_STATUS';


export const setNetStatus = ( payload ) => ({
    type: SET_NET_STATUS ,
    payload
  });


export const setFrozenApp = ( payload ) => ({ //busy App
    type: SET_FROZEN_APP ,
    payload
  }); 
  
  export const setUpdatingStatus = ( payload ) => ({ //updating status
    type: SET_UPDATING_STATUS ,
    payload
  });   