import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadConsumers } from '../actions/consumersActions';
import { changeCalendar } from '../actions/kindCalendarActions';
import { changeCurrentSite } from '../actions/currentVisibleSiteActions';
import { setDate } from '../actions/dateAction';
import { setVisits } from '../actions/visitsActions';

import { loadConsumersFromDB , loadVisitsOfDayFromDB } from '../customfunc';

import "./AddNewConsumer.css";
import {urlAppFile , loadVisitOfConsumer } from"../global";

//czy menu ma być przesuwane myszką
//tymczasowo wyłączone ponieważ wymaga poprawy
const staticMenu = true;

let pos = {x: 0 , y: 0};  
let mMove = false;


const SmsSendBox=()=> {

const handleSendSMS=()=> {

    const debug = true;    
    let polishCharacters = false;

    let phone = document.getElementById('phoneInput').value;
    if(phone==='' || phone===null) return false;

    let smsMessage = document.getElementById('SmsSendTextarea1').value;  
    if(smsMessage==='' || smsMessage===null) return false;

    if(!polishCharacters) {
        smsMessage = smsMessage.replaceAll( 'ą' , 'a');
        smsMessage = smsMessage.replaceAll( 'ę' , 'e');
        smsMessage = smsMessage.replaceAll( 'ś' , 's');
        smsMessage = smsMessage.replaceAll( 'ł' , 'l');
        smsMessage = smsMessage.replaceAll( 'ć' , 'c');
        smsMessage = smsMessage.replaceAll( 'ż' , 'z');
        smsMessage = smsMessage.replaceAll( 'ź' , 'z');
        smsMessage = smsMessage.replaceAll( 'ó' , 'o');
    }

    var objCon = {
        Action: "sendSMS",
        Phone: phone ,
        Message: smsMessage
        };      

    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  

    if(debug) {
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(data => {  
        if(data.list[0].status === 'QUEUE') {
            document.getElementById('SmsSendTextarea1').value = "";  
            }
            
        })
        .catch(err => {
            console.warn("Error: "+err)
            alert('Wystąpił błąd:  '+err);
            }); 
}
    else {
        console.log(smsMessage);
    }


}

return (
<>
    <div className="mb-3">
    <label htmlFor="SmsSendTextarea1" className="form-label">Wyślij wiadomość SMS do klienta</label>
    <textarea className="form-control" id="SmsSendTextarea1" rows="3"></textarea>
    <div >
        <button type="button" className="btn btn-dark btn-sm button-make" onClick={handleSendSMS} >Wyślij wiadomość SMS</button>
    </div> 
    </div>

</>    
)    
}


const VisitHistory=(props)=> {
const [consumerVisit , setConsumerVisit] = useState( null ); 
const dispatch = useDispatch();   


useEffect(()=> {
loadVisitOfConsumer(props.consumer.id).then( response => setConsumerVisit(response) );
} , [] ); 

const handleDCHistoryVisit=(date)=> {
const _date = new Date(date);    
dispatch( changeCurrentSite(1) );  
dispatch( changeCalendar ( { kind: 2 , data: null }) );  
dispatch( setDate(_date) );   
loadVisitsOfDayFromDB( _date ).then(resolve => dispatch( setVisits(resolve) ) ); //Tu jest błąd
}

const showConsumerVisit=()=> {
 return consumerVisit.map ( val => {
        let date = new Date(val.startDate);
        let atlVisit = `${date.getDate()<10 ? '0':''}${date.getDate()}-${date.getMonth()<9 ? '0':''}${date.getMonth()+1}-${date.getFullYear()}${val.alt ? ' - usługa: ':''}${val.alt}`;
        return(  <option key={val.id} onDoubleClick={ ()=>handleDCHistoryVisit(date) }>{atlVisit}</option> )     
         }
      );

}

if ( consumerVisit ) { return ( 
<>
    <div>Historia wizyt</div>
    <select className="form-select" size="3" aria-label="size 3 select example">
    { showConsumerVisit()  }
    </select>
</>    
)
}
else {
return ( null );    
}
}

const OptionSendMessage=()=>{

return(
<>

</>    
)    
}

const AddNewConsumer = (props) => {

const [busyApp , setBusyApp] = useState(false);
const dispatch = useDispatch();

useEffect( ()=> {

})

    const handleMouseDown=(e)=>
    {
    if(!staticMenu) {
        let mainBar = document.getElementById('mainBar');
        mainBar.style.position = 'absolute';
        let xFar = e.pageX - mainBar.offsetLeft ;
        let yFar = e.pageY -mainBar.offsetTop ;
        pos = { 
            x: xFar,
            y: yFar
        }
        mMove = true;
    }
    }  

    const handleMouseUp=(e)=>
    {
        mMove = false;
    } 

    const handlerMouseMove=(ex)=>
    {
        if(mMove) {
            let mainBar = document.getElementById('mainBar');
            mainBar.style.left = `${ex.pageX-pos.x}px`; 
            mainBar.style.top = `${ex.pageY-pos.y}px`;
            }
    }
 
 window.addEventListener('mouseup' , ()=>{ 
 mMove = false;
 })  

 const handleMakeNewConsumer = ()=> {
 let name = document.getElementById('nameInput').value;
 let surname = document.getElementById('surnameInput').value; 
 if(name==="" && surname==="") {
     alert('Imię lub nazwisko musi być wypełnione!');
    return false;
    }
    var objCon = {
        Action: "saveConsumer",
        id: -1 ,
        name: name,
        surname: surname,
        phone: document.getElementById('phoneInput').value
        };      
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    setBusyApp(true);
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(data => {
         if(data.status === "success") {
            alert("Zapisano klienta");
            props.handleAddNewConsumer();
            loadConsumersFromDB().then((json)=> {
                dispatch( loadConsumers(json) );
                setBusyApp(false);
            });
            }
        else if(data.status === 'error ') {
            alert('Nie można zapisać klienta do bazy danych');
        }
        else {
            alert('Nie można zapisać klienta do bazy danych');
        }
        })
        .catch(e=>alert(e)); 
 }

 const handleEditConsumer = ()=> {
    let name = document.getElementById('nameInput').value;
    let surname = document.getElementById('surnameInput').value; 
    if(name==="" && surname==="") {
        alert('Imię lub nazwisko musi być wypełnione!');
       return false;
       }
       var objCon = {
           Action: "saveConsumer",
           id: props.consumer.id,
           name: name,
           surname: surname,
           phone: document.getElementById('phoneInput').value
           };      
       var urlData = new URLSearchParams();
       urlData.append('var1', JSON.stringify(objCon) );  
       setBusyApp(true);
       fetch(urlAppFile , {
           method: 'POST' ,  
           body: urlData  
           })
           .then(response => response.json())
           .then(data => {
            if(data.status === "success") {
                alert("Zapisano klienta");
                props.handleAddNewConsumer();
                loadConsumersFromDB().then((json)=> {
                    dispatch( loadConsumers(json) );
                    setBusyApp(false);
                });
                }
            else if(data.status === "error") {
                alert(data.content);
                }  
            else if(data.error) {
                    alert(data.error);
                    }        
           }) 
    }

const handlePreviewConsumer = ()=> {

    }   

 //----------------------------------------------------------------------
let titleBar = "Nowy Klient"
let nameDefault = "";
let surnameDefault = "";
let phoneDefault = "";
let nameButton1 = ""
let handle1Button = null;

if(props.actionType === "editConsumer" ) 
{
    titleBar = "Edycja klienta";
    nameDefault = props.consumer.name; 
    surnameDefault = props.consumer.surname;
    phoneDefault = props.consumer.phone;
    nameButton1 = "Zapisz"
    handle1Button = handleEditConsumer;
}
if(props.actionType === "previewConsumer")  
{
    titleBar = "Podgląd klienta";
    nameDefault = props.consumer.name; 
    surnameDefault = props.consumer.surname;
    phoneDefault = props.consumer.phone;
    nameButton1 = "";
    handle1Button = handlePreviewConsumer;
} 
if(props.actionType === "newConsumer")  
{
    nameDefault = ""; 
    surnameDefault = "";
    phoneDefault = "";
    nameButton1 = "Utwórz";
    handle1Button = handleMakeNewConsumer;
}   

 return ( 
        <>
        <div className='bgOpenMenu'></div>
        <div className="openMenu">
            <div className="mainRow">
                
                <div className="row-1" >
                    <div id="mainBar" className="mainBarNewCon" onMouseMove={handlerMouseMove}>
                        <div id="topBar" className="topBar" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                            <p style={{textAlign: "left"}}>{titleBar}</p>
                        </div>
                        <div className="centralBar">
                            <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Imię: </span>
                            <input type="text" id="nameInput" className="form-control" placeholder="Imię" aria-label="Username" aria-describedby="basic-addon1" defaultValue={nameDefault}/>
                            </div>

                            <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Nazwisko: </span>
                            <input type="text" id="surnameInput" className="form-control" placeholder="Nazwisko" aria-label="Surename" aria-describedby="basic-addon1" defaultValue={surnameDefault}/>
                            </div>

                            
                            <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Numer telefonu: </span>
                            <input type="text" id="phoneInput" className="form-control" placeholder="Numer telefonu" aria-label="PhonaNumber" aria-describedby="basic-addon1" defaultValue={phoneDefault}/>
                            </div>
                            <div>
                            {(props.actionType==="previewConsumer" ) && <VisitHistory consumer={props.consumer}/>}
                            </div>

                            <div>
                            {(props.actionType==="previewConsumer" ) &&  <SmsSendBox />}

                            </div>

                            <div>
                            {(props.actionType==="newConsumer" || props.actionType==="editConsumer" || props.actionType==="previewConsumer" ) &&  <OptionSendMessage />}

                            </div>

                            <div style={{clear:"both"}}></div>
                        </div>
                        <div className="bottomBar">
                            <div className="buttons_main">
                            { nameButton1!=='' && <button type="button" className="btn btn-dark btn-sm button-make" disabled={busyApp && 'disabled'} onClick={handle1Button}>{nameButton1}</button> }                  
                            <button type="button" className="btn btn-dark btn-sm button-close" onClick={props.handleAddNewConsumer}>Zamknij</button>
                            </div></div>
                    </div>
                </div>
                
            </div>
        </div>
        </>
     );
}
 
export default AddNewConsumer;