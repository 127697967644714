import { defaultCalendar } from '../global';
import { CHANGE_KIND_CALENDAR } from '../actions/kindCalendarActions';

export const kindCalendar = (state = defaultCalendar, action) => { 
    switch (action.type) { 
      case CHANGE_KIND_CALENDAR:
        return action.payload;
       
      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }

