import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDate } from '../actions/dateAction';
import { changeCalendar } from '../actions/kindCalendarActions';
import { setVisits } from '../actions/visitsActions';

import { loadVisitsOfDayFromDB } from '../customfunc';

import './YearCalendarApp.css';


const YearCalendar=(props)=> {

const Months = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"]; 
const shortDays = ["Pn", "Wt", "Śr", "Cz", "Pt", "So", "Nd"]; 
const maxDaysCalendar = 42;   

const InitCalendarData = ()=> {
    let tempObj = [];
    for(let i = 0 ;  i < 43 ; i++) {
        tempObj[i] = {
        id: i,
        day: 0 , 
        month: 0 ,
        year: 0 ,
        name: "" , 
        shortName: "" ,
        currentMonth: true
        }
    }
    tempObj[1].name = "Poniedziałek";
    tempObj[2].name = "Wtorek" ;  
    tempObj[3].name = "Środa" ;  
    tempObj[4].name = "Czwartek" ;  
    tempObj[5].name = "Piątek" ;  
    tempObj[6].name = "Sobota" ;  
    tempObj[7].name = "Niedziela" ;  
    tempObj[1].shortName = "Pn";
    tempObj[2].shortName = "Wt" ;  
    tempObj[3].shortName = "Śr" ;  
    tempObj[4].shortName = "Cz" ;  
    tempObj[5].shortName = "Pt" ;  
    tempObj[6].shortName = "So" ;  
    tempObj[7].shortName = "Nd" ; 
    return tempObj;  
    }
    
    const GetDaysInMonths = (month , year)=> {
        let ndays;
         if (month === 0 || month === 2 || month === 4 || month === 6 || month === 7 || month === 9 || month === 11) ndays = 31;
        else if (month === 3 || month === 5 || month === 8 || month === 10) ndays = 30;
            else {
                if (year % 4 !== 0) ndays = 28;
                if (year % 4 === 0) {
                    if (year % 100 === 0 && year % 400 !== 0) ndays = 28;
                    else ndays = 29;
                }
            }
       return ndays; 
    }
    
    const SetDayObjects = (month, year )=> {    
    //funkcja wyliczające ile dni ma miesiac 
    //this function count how days is in the month
    let ndays = GetDaysInMonths(month , year);
    //-----------------------------
    
        let startDay = new Date(year, month, 1);	//pobranie pierwszego dnia miesiąca bierzącego/przełącznonwgo
        let firstD = startDay.getDay(); // pobranie numeru dnia tygodnia
        if (firstD === 0)
            firstD = 7;	//jeśli niedziela to ma numer 7----na potrzeby kalendarza
    
        let tempCal = [];
        tempCal = InitCalendarData();  

        for (let i = 1; i <= ndays; i++) //wypełnianie kalendarza datami
        {
            let iDay = i + firstD - 1;
            tempCal[iDay].day = i;
            tempCal[iDay].month = month;
            tempCal[iDay].year = year;
        }    
        //wypełnianie do przodu
        
        for (let i = 0; i <= maxDaysCalendar - ndays - firstD ; i++)
        {
            let iDay = ndays + firstD + i;
            tempCal[iDay].day = i+1;
            tempCal[iDay].month = month+1;
            let iY = ((month+1) > 11) ? year+1 : year;
            tempCal[iDay].currentMonth = false;
            tempCal[iDay].year = iY;
        }
          
        //wypełnianie do tyłu
        let _month = month-1;
        //month = month - 1;
        if (_month === -1) _month = 11;
        if (_month === 0 || _month === 2 || _month === 4 || _month === 6 || _month === 7 || _month === 9 || _month === 11) ndays = 31;
        else if (_month === 3 || _month === 5 || _month === 8 || _month === 10) ndays = 30;
        else {
            if (year % 4 !== 0) ndays = 28;
            if (year % 4 === 0) {
                if (year % 100 === 0 && year % 400 !== 0) ndays = 28;
                else ndays = 29;
            }
            }

        startDay = new Date(year, _month , 1);	//pobranie pierwszego dnia miesiąca bierzącego/przełącznonwgo
        var backD = startDay.getDay(); // pobranie numeru dnia tygodnia
        if (backD === 0) backD = 7;	//jeśli niedziela to ma numer 7----na potrzeby kalendarza
        for (let i = 0; i < firstD; i++)
        {
            let iDay = ndays - firstD + i;
            tempCal[i].day = iDay + 1;
            tempCal[i].month = _month;
            let iY = (_month === 11) ? year-1 : year
            tempCal[i].year = iY;
            tempCal[i].currentMonth = false;
        }
        return tempCal;   
    }

    //handle click day on the year calendar
    const handleClickDay = (data) => {
        let nD = new Date(data.year , data.month , data.day);
        loadVisitsOfDayFromDB(nD).then(resolve => dispatch( setVisits(resolve) ) );        
        dispatch( setDate(nD) );
        dispatch( changeCalendar({kind: 2} ) );
    }
     

    //useDispatch
    const dispatch = useDispatch();
    //useSelector
    const currentDateTime = useSelector(store => store.currentDateTime );
    //const kindCalendar = useSelector(store => store.kindCalendar);
    //const optionVisit = useSelector(store => store.optionVisit);
    const isVisibleNotCurrentDays = parseInt(props.visibleNotCurrentDays) ? true : false ;
    let year = currentDateTime.getFullYear();
    let month = props.month;
    
    let tCalData = null;
    tCalData = SetDayObjects(month , year );


    let dayBox = tCalData.map( (val , index)=> {
    if (index > 0 ) { 
        let classNameDiv = 'col';
        let handleClickDate = null;
        let tooday = new Date();

        if((val.id === 6 ) || (val.id === 13 ) || (val.id === 20 ) || (val.id === 27 ) || (val.id === 34 ) || (val.id === 41 )) classNameDiv += ' saturdayDiv'; //if is saturday then coloring
        else if((val.id === 7 ) || (val.id === 14 ) || (val.id === 21 ) || (val.id === 28 ) || (val.id === 35 ) || (val.id === 42 )) classNameDiv += ' sundayDiv'; //if is sunday then coloring
        if(val.month !== props.month) classNameDiv += ' prevMonth'; //if is a different day in the current month, you color
        else {
            handleClickDate = ()=> handleClickDay(val) ;
            classNameDiv += ' currentMonth';
        }
        if((val.day === tooday.getDate()) && (val.year === tooday.getFullYear() ) && val.month === tooday.getMonth() ) classNameDiv += ' toodayDiv';
        
        let visibleDay = false;
        if ((!isVisibleNotCurrentDays) && val.currentMonth) visibleDay = true;
        if (isVisibleNotCurrentDays) visibleDay = true;
        
        ;
        // visibleDay = isVisibleNotCurrentDays ? false : true;

        return (
        <div key={(val.id+2)*(val.month+3)*(val.year+4)} className={classNameDiv} style={{width:'14.285%' , flex: '1 0 auto'}} onClick={handleClickDate} >
            <div  style={{textAlign: 'center'}}>
                <span >{visibleDay ? val.day : ''} </span> 
            </div>
        </div>

        );
        }   
    return null;
    })

    const headerCal = shortDays.map(val => {
       return(
        <div key={val} className={'shortDay'} style={{width:'14.285%' , flex: '1 0 auto'}} >
            <div  style={{textAlign: 'center'}}>
                <span >{val}</span> 
            </div>
        </div>
       );
    });

    return (
    <>
    <div className='monthDiv'>
        <div className='monthName'>{Months[props.month]}</div>
        <div className="row" >
            {headerCal}       
            {dayBox}
            </div>        
        </div>
    </>    
    )
}

const YearCalendarApp=(props) => {
    const settings = useSelector(store => store.settings); 
    const visibleNotCurrentDays = settings.visible_not_current_days;
    const verticalAnnualCalendar = settings.vertical_annual_calendar;

    return( 
        <>
        <div className='col'>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-4'  style={{margin: '15px 0px 15px 0px'}}>
                    <div className='col'>    
                        <YearCalendar month={0} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}  />
                    </div>
                    <div className='col'>  
                        <YearCalendar month={1} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={2} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={3} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
            </div>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-4 ' style={{margin: '15px 0px 15px 0px'}} >
                    <div className='col'>    
                        <YearCalendar month={4} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={5} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={6} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={7} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
            </div>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-4'  style={{margin: '15px 0px 15px 0px'}}>
                    <div className='col'>    
                        <YearCalendar month={8} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={9} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={10} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
                    <div className='col'>  
                        <YearCalendar month={11} visibleNotCurrentDays={visibleNotCurrentDays} verticalAnnualCalendar={verticalAnnualCalendar}/>
                    </div>
            </div>
        </div>
        </>
    );
}

export default YearCalendarApp;