import { NOW_DATE , SET_DATE ,  PREV_MONTH , NEXT_MONTH , PREV_DAY , NEXT_DAY } from '../actions/dateAction';

export const currentDateTime = (state = null, action) => { // (1)
    switch (action.type) { // (2)
      case NOW_DATE: {
        return new Date();
      }
      
      case SET_DATE: {
        return action.payload;
      }

      case PREV_MONTH: {
      let date = new Date(state);  
      date.setMonth(date.getMonth()-1);
      return date;  
      }    

      case NEXT_MONTH: {
      let date = new Date(state);  
      date.setMonth(date.getMonth()+1);
      return date;    
      }
            
      case PREV_DAY: {
      let date = new Date(state);  
      date.setDate(date.getDate()-1);
      return action.payload;        
      }  
        
      case NEXT_DAY: {
      let date = new Date(state);  
      date.setDate(date.getDate()+1);
      return action.payload; 
      }
 
      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }