import { REFRESH } from '../actions/refreshActions';

export const refresh = (state = false, action) => { // (1)
    switch (action.type) { // (2)
      case REFRESH: {
       return !state; 
      }       

      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }