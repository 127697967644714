import React from 'react';
import { useDispatch , useSelector} from 'react-redux';

import { changeCurrentSite } from '../actions/currentVisibleSiteActions';
import { setWorkers } from '../actions/workersActions';


import './MenuApp.css';

const CurrentWorkersList=()=> {    
const workers = useSelector(store=>store.workers);
const dispatch = useDispatch();

const handleChangeActiv=( id , visible )=> {
    let _vis = visible==="1" ? "0" : "1";  
    //wymuszenie zczytania tablicy
    let _work = workers.slice();
    _work[id].visible = _vis;
    dispatch( setWorkers( _work ) );       
    }     

const showCurrentWorkers=()=> {       
    let _workers = workers.map( (val , index) => {
        if( !parseInt(val.active) ) return false;
        return (
        <div key={val.id}className="form-check">
        <input key={val.id} className="form-check-input" type="checkbox" onChange={()=>handleChangeActiv(index , val.visible )} checked={parseInt(val.visible)===1 ? true : false } id="flexCheckDefault" />
        <label className="form-check-label" forhtml="flexCheckDefault">
            {val.namesurname}
        </label> 
        </div>  
        )
}
) 
return _workers;    
} 
    
return (
    
    <>
    <div style={{margin: "10px 0px 10px 0px" , borderTop: "1px grey solid" , borderBottom: "1px grey solid" , width: "100%" }}>
    <div style={{margin: '10px 2px 10px 5px'   }}>
     {workers && showCurrentWorkers() }   
     </div>
     </div>
     </>

);    
}

const MenuApp =()=> {
    
    const dispatch = useDispatch();
    const workers = useSelector(store => store.workers ) ;  

        return ( 
            <>
            <div className="col-md-2">
                <div id="center_left">
                    <div id="cal_button" onClick={()=>dispatch( changeCurrentSite(1) )}>Kalendarz</div>
                    <div id="work_button" onClick={()=>dispatch( changeCurrentSite(4) )}>Pracownicy</div>
                    <div id="cons_button" onClick={()=>dispatch( changeCurrentSite(5) )}>Klienci</div>
                    <div id="sett_button" onClick={()=>dispatch( changeCurrentSite(7) )}>Ustawienia</div>
                </div>

                <div>
                 {workers && <CurrentWorkersList /> }   
                </div>
                <div> 
                </div>
            </div>
            </>
         );
    
}
 
export default MenuApp;