import { VISIBLE , NONE} from '../actions/bVisibleMenuActions';

export const bVisibleMenu = (state = true, action) => { // (1)
    switch (action.type) { // (2)
      case VISIBLE:
        return !state;
       
        case NONE:
            return !state;

      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }