import { urlAppFile  } from '../src/global';

//needed to frozen app in async communication from API
//użuwane aby zapobiedz wysyłaniu/odbieraniu danych w jednym momencie.
//19.03.2024 - obsługa zmiany daty kalendarza powinna zablokować akcje dynamicznej aktualizacji
//appNetStatus - jest aktywowane w momencie zmiany kalendarza
export const FREE = 0;
export const BUSY = 1;
let statusNetApp = FREE;
export const appNetStatus=(status = -1)=>{
if( status === -1 ) return statusNetApp;
if(status > -1) {
  statusNetApp = status;
  return status;
  }
}
//------------------------------------------------------------
/*
export const CHANGING = 1;
let updatingApp = FREE;
export const changeOfDay=(status = -1)=>{
if( status === -1 ) return updatingApp;
if(status > -1) {
  updatingApp = status;
  return updatingApp;
  }
}
*/
//-----------------------------------------------------------

let interval1 = 0;
export function setIdInterval(id) {interval1 = id};
export function getIdInterval() {return interval1};

//function to check
export const isSession=()=> {
  return new Promise((resolve) => {       
      var objCon = { 
        Action: "isSession"
      };
      var urlData = new URLSearchParams();
      urlData.append('var1', JSON.stringify(objCon) );  
      fetch(urlAppFile , {
          method: 'POST' , 
          credentials: 'include' , 
          /*mode: corsMode ,
          headers: {
            'Content-Type': 'application/json' 
          },*/
          body: urlData  
          })
          .then(response => {
            if(response != null) return response.json();
          })
          .then(json => {
          resolve( json ); 
          })
          .catch((e)=>{
            console.log('Błąd aplikacji: ' + e);
            //reject('{"status" : "notLogged"}');
          });  
  });
  }

  export const logging=(login , password)=> {
    return new Promise((resolve ) => {       
        var objCon = { 
          Action: "login",
          login,
          password
        };
        var urlData = new URLSearchParams();
        urlData.append('var1', JSON.stringify(objCon) );  
        fetch(urlAppFile , {
          method: 'POST',
          body: urlData
        } )
        .then(response => response.json())
        .then(json => { resolve( json ) }
        )
        .catch((e)=>alert("błąd bazy danych: " + e))  
    });
    }

    export const logoutUser=()=> {
      return new Promise((resolve) => {       
          var objCon = { 
            Action: "logout"
          };
          var urlData = new URLSearchParams();
          urlData.append('var1', JSON.stringify(objCon) );  
          fetch(urlAppFile , {
              method: 'POST' , 
              body: urlData  
              })
              .then(response => {
                if(response != null) return response.json();
              })
              .then(json => {
              resolve( json ); 
              })
              .catch((e)=>{
                console.log('Błąd aplikacji: ' + e);
              });  
      });
      }   
      
let counterErrorLVD = 0;

export const loadVisitsOfDayFromDB=(date = null)=> {
  //try{
  return new Promise((resolve , reject) => {
  let _date = date===null ? new Date() : date;
  let sD = new Date( _date.getFullYear() , _date.getMonth() , _date.getDate() );
  let eD = new Date( _date.getFullYear() , _date.getMonth() , _date.getDate()+1 );
    
    var objCon = { 
      Action: "LoadVisitsOfDay" ,
      startDate: sD ,
      endDate: eD 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );
    //try {  
      fetch(urlAppFile , {
          method: 'POST' ,  
          body: urlData  
          })
          .then(response => {
            if(response.ok) return response.json() 
            else throw new Error('zgłoszono wyjątek odpowiedzi fetch!!!');
          })
          .catch(err => {
              let errObj = {
              error: err ,
              counter: counterErrorLVD , 
              date : new Date().toString()
            }
            
            reject( errObj );
          })
          .then(json => {
            counterErrorLVD = 0;
            resolve(json) ;
          })
          .catch(errJSON=> {
            console.log("error parser: " + errJSON);
          })
        // .catch((e)=>{
        //   //console.log(e);
        //     //if(typeof e === 'object' && (!Array.isArray(e)) && e !== null  ) {
        //       //if(e.error) {
        //         let errObj = {
        //           error: e ,
        //           counter: counterErrorLVD , 
        //           date : new Date().toString()
        //         }
                
        //         reject( errObj );
        //       //}
        //       //else return e;
        //       //}
        //   })

      //}
     //catch(error) {
     // console.log('ERRROR: ' , error);
     //}     
      })
    
    //}
    //catch(e) {
    //  return new Promise((reject) => {
      .catch(e=> {
        //obsługa braku sieci
        if(typeof e === 'object' && (!Array.isArray(e)) && e !== null  ) {
          if(e.error) {
            let errObj = e;
            errObj.counter = counterErrorLVD;
            errObj.date = new Date().toString();
            return errObj;
            //reject(errObj);
          }
          else return( e);
          }
        });
       //});
//        }
}

//function to load workers ro database
export const loadWorkersFromDB=()=> {
return new Promise((resolve , reject) => {       
    var objCon = { 
      Action: "loadWorker"
    };
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
        resolve( json ); 
        }
        )
        .catch(()=>alert('błąd bazy danych'))  
});
}

//load consumers from DB and write to global store by redux
export const loadConsumersFromDB=()=> {  
  return new Promise((resolve , reject)=> {
  var objCon = { Action: "loadConsumers" };
  var urlData = new URLSearchParams();
  urlData.append('var1', JSON.stringify(objCon) );  
  fetch(urlAppFile , {
      method: 'POST' ,  
      body: urlData  
      })
      .then(response => response.json())
      .then(json => {               
      resolve( json );      
      }
      )  
  } )
}

export const loadSettingsFromDb=()=> {
  return new Promise((resolve, reject) => {
    let objCon = { 
      Action: "loadSettings" 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          //dispatch(loadSettings(json[0]));
          resolve(json);
        }
        ) 
  })

}


export const getPointsSMSApi=()=> {
  return new Promise((resolve, reject) => {
    let objCon = { 
      Action: "getPointsSMSApi" 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          //dispatch(loadSettings(json[0]));
          resolve(json);
        }
        )
        .catch(()=>alert('błąd smsapi.pl'))   
  })

}