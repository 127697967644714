import React, { useEffect , useState } from 'react';
import {useSelector , useDispatch } from 'react-redux';
import { bVisibleMenu } from '../actions/bVisibleMenuActions.js';
import { ClockComponent } from './ClockComponent.jsx';

import { webAddress , MAX_WIDTH_MOBILE  } from '../global';
import { logoutUser , getIdInterval } from '../customfunc';

import './Top.css';
//import { mode } from 'crypto-js';

const MOBILE = 1;
const WIDE = 2;
const MessagePanel=(props)=>{
const points = props.points > 0 ? props.points : 0;
    return (
        <>
        <div className='bgOpenMenu'>
            <div className='openMenu'>
                <div>
                    <div className='row-1'>
                        <div className='mainBarVisit '>
                            <div className='topBar'>
                                Panel wiadomości
                            </div>
                            <div className='centralBar'>
                                <div className='centralBarMessagePanel'>
                                    <div className='general'>
                                        <label className='generalLabel' style={{backgroundColor: '#f0f0f0'}} >Powiadamianie SMS o wizytach</label>
                                        <div className='mt-2'>Liczba wiadomości SMS do wysłania: {points}</div>
                                    </div>   
                                </div>
                            </div>
                            <div className='bottomBar'>
                                <div className='buttons_main'>
                                    <button type="button" className="btn btn-dark btn-sm button-make" onClick={()=>props.handleCloseMessagePanel()}>Zamknij</button>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        </>
    )
}
const TopApp = () => {
//redux store
const user = useSelector(store => store.user);
const appSettings = useSelector(store => store.netStatus);
const settings = useSelector(store => store.settings);
const dispatch = useDispatch();

const [sizeWindow, setSizeWindow] = useState({width:0, height: 0});
const [messagesPanel, setMessagesPanel] = useState(false);

//functions
const logout=()=> {
    logoutUser().then((json)=>{
        if(json !== undefined) {
          if(json.session.status === 'notLogged') {
            clearInterval(getIdInterval());
            document.location.href=webAddress; 
          }
          }
        });
}

const getPoints=()=>{
    let smsPrice = 0;
    if(typeof settings === 'object' && (!Array.isArray(settings)) && settings !== null  ) {
        const points = settings.points;
        smsPrice = settings.sms_api_sms_price;
        return Math.floor( points/smsPrice);
    }
    else return 0;
    
}

const handleCloseMessagePanel=()=> {
    setMessagesPanel(false);
}

const handleAlertClick=()=>{
    setMessagesPanel(true);
}



function updateSizeWindow() {
    setSizeWindow({width:document.getElementsByTagName('body')[0].getBoundingClientRect().width, height:document.getElementsByTagName('body')[0].getBoundingClientRect().height } );
}
//hooks 
useEffect( ()=> {
      window.addEventListener('resize', updateSizeWindow); //dodanie eventu window size;
      updateSizeWindow();
      return () => window.removeEventListener('resize', updateSizeWindow);
} , []);

//const varible
let st = false;
if(typeof user === 'object' && (!Array.isArray(user)) && user !== null  ) {
    if(appSettings.netStatus === true) st = true;
    else st = false;
}

const imgIconNet = st ? 'images/network_ok.png' : 'images/network_error.png';
let imgAlertIcon = 'images/ok_64.png';
const points = getPoints();
let contentAlert = 'Wszystko w porządku';
const contentNetStatus = st ? 'Sieć działa poprawnie' : 'Sieć działa niepoprawnie';

let modeWindow = WIDE;
if( sizeWindow.width > MAX_WIDTH_MOBILE ) modeWindow = WIDE;
else modeWindow = MOBILE;
let classInfoPanel = 'col-5';
let usPn = 'col-8';
let clock = 'col-4';
//let menuVis = true;
if(modeWindow === WIDE) {
    classInfoPanel = 'col-5';
    usPn = 'col-8';
    clock = 'col-4';
//    menuVis = true;
}
if(modeWindow === MOBILE) {
    classInfoPanel = 'col-6';
    usPn = 'col-12';
    clock = '';
//    menuVis = false;
}

//widoczność ostrzeżenia dotyczącego ilości sms-ów do wysłania
const limitAlertSMS = ()=>{
    if(settings){
        if(settings.sms_api_min_limit_alert) return parseInt(settings.sms_api_min_limit_alert)
        else return 0;
    }
    return 0;
}

const alertApp = ((limitAlertSMS()>points) && (points>-1))  ? true : false;
if(alertApp) {
    imgAlertIcon = 'images/alert_64.png';
    contentAlert = `Pozostała ilość wiadomości sms: ${points}`;
}

    return ( 
     <>
        {messagesPanel && <MessagePanel points={points} handleCloseMessagePanel={handleCloseMessagePanel}/>}
        <div id="top" className="top"> 
            <div className='row'>
                <div className='col-md-1 visibleMenuMain'>
                    <div className='row visibleMenuRow'> 
                        <div className='col visibleMenu'>
                            <div className='visibleBox'>
                                <div className="menu_click">
                                    <img src="images/menu.png" width="32" height="32" border="0" alt="" onClick={()=>dispatch(bVisibleMenu())}/>
                                </div>
                            </div>
                        {/*</div>
                        <div className='col'>*/}
                        </div>
                        
                    </div>
                </div>
                <div className={classInfoPanel}>
                    <div className='infoPanel'>
                        <div>
                            <span className="salonName">Zarządzanie salonem - CINNAMON</span>
                        </div>
                        <div>
                            <span className='loginText'>zalogowany: {user.session.name}</span>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='row h100'>
                        <div className={usPn}>    
                            <div className='userPanel'>
                                
                                { (alertApp && (modeWindow === WIDE)) && <div><span className="smsToSends">Ilość wiadomości: {points}</span></div> } 
                                <div><img className='alertIcon' src={imgAlertIcon} alt={contentAlert} onClick={handleAlertClick} /></div>
                                <div><img className='networkIcon' src={imgIconNet} alt={contentNetStatus} /></div>
                                <div><img className="logoutIcon" src="images/exit.png" alt="logout" onClick={logout}/></div>
                            </div>
                        </div>
                        <div className={clock + ' clock'} >
                            <div className='clockDiv'>
                                <div className='clockText' >
                                    <ClockComponent />
                                </div>
                            </div> 
                        </div>

                    </div>
                </div>   
                        
                
            </div>
        </div>
        

     </> 
     );
}
 
export default TopApp;
