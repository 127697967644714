import { LOAD_SETTINGS , SET_POINTS } from '../actions/settingsActions';

//const settState = {};
export const settings = (state = null, action) => { // (1)
    switch (action.type) { // (2)
      case LOAD_SETTINGS: {
      //jeżeli nie występuje   
      let _p = -999;
      if(state) {
        if(state.points) {
          _p = state.points>-1 ? state.points : -999;
        }
      }  
      let _st = {
        active_notification:action.payload.active_notification,
        buttons_services:action.payload.buttons_services,
        company_logo:action.payload.company_logo,
        company_long_name:action.payload.company_long_name,
        company_short_name:action.payload.company_short_name,
        day_interval:action.payload.day_interval,
        sender_name:action.payload.sender_name,
        text_notification:action.payload.text_notification,
        time_zone:action.payload.time_zone,
        vertical_annual_calendar:action.payload.vertical_annual_calendar,
        visible_name_worker:action.payload.visible_name_worker,
        visible_not_current_days:action.payload.visible_not_current_days,
        points:_p,
        sms_api_sms_price:action.payload.sms_api_sms_price,
        sms_api_min_limit_alert:parseInt(action.payload.sms_api_min_limit_alert)
      }
       return _st; 
      } 
      case SET_POINTS: {
        let _st = {
          active_notification:state.active_notification,
          buttons_services:state.buttons_services,
          company_logo:state.company_logo,
          company_long_name:state.company_long_name,
          company_short_name:state.company_short_name,
          day_interval:state.day_interval,
          sender_name:state.sender_name,
          text_notification:state.text_notification,
          time_zone:state.time_zone,
          vertical_annual_calendar:state.vertical_annual_calendar,
          visible_name_worker:state.visible_name_worker,
          visible_not_current_days:state.visible_not_current_days,
          points:parseFloat(action.payload),
          sms_api_sms_price:state.sms_api_sms_price,
          sms_api_min_limit_alert:state.sms_api_min_limit_alert
        }
         return _st; 
        }       

      default: {
        return state ;
        }
    }
  }