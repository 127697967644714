import { defaultSite } from '../global';

import { CHANGE_SITE } from '../actions/currentVisibleSiteActions';

export const currentVisibleSite = (state = defaultSite, action) => { // (1)
    switch (action.type) { // (2)
      case CHANGE_SITE:
        return action.payload;
       
      default: {
        //console.warn(`nieznana akcja ${action}`);
        return state ;
        }
    }
  }